import { createContext, useContext } from "react";

export interface ITokenContext {
    token: React.MutableRefObject<string>;
};

export const TokenContext = createContext<ITokenContext | null>(null);

export function useTokenContext(): ITokenContext | null {
    return useContext(TokenContext);
}