import { ButtonHTMLAttributes } from "react";
import "./justButton.css";

interface IJustButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    addedClasses?: string[];
    variant?: 'white' | 'opacity' | 'blue'; 
}

export default function JustButton({ 
    children,
    variant = 'opacity',
    addedClasses,
    ...other
}: IJustButtonProps) {

    return (
        <button className={`just_btn ${addedClasses}`} data-variant={variant} {...other}>
            {children}
        </button>
    );
};