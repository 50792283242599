import './App.css';
import { StartPage } from './routes/StartPage';

function App() {
  return (
    <div className="app">
      <StartPage />
    </div>
  );
}

export default App;
