import { SetStateAction, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { EndGameType } from "../../routes/Endgame";
import { ITimerState } from "../../contexts/GameContext/GameContext";
import './Timer.css'

type Props = {
  timerState: ITimerState;
  setTimerState: React.Dispatch<SetStateAction<ITimerState>>;
  setEndGameState: React.Dispatch<SetStateAction<boolean>>;
};

export const Timer = ({
  timerState,
  setTimerState,
  setEndGameState,
}: Props) => {
  
  const { minutes, seconds } = timerState;

  const formatTime = (time: number) => (time < 10 ? '0' : '') + time;

  const navigate = useNavigate();

  useEffect(() => {
    if (!timerState.isRunning) return;

    const interval = setInterval(() => {
      if (seconds > 0) {
        setTimerState({ 
          minutes: timerState.minutes, 
          seconds: timerState.seconds - 1, 
          isRunning: timerState.isRunning, 
        });
      } 
      
      else {
        clearInterval(interval);
        setTimerState({
          minutes: timerState.minutes,
          seconds: timerState.seconds,
          isRunning: false,
        });
        
        setEndGameState(true);
        // navigate to lose screen
        navigateToLoseScreen(navigate);
      }
    }, 1000);
  
    return () => clearInterval(interval);
  }, [minutes, seconds]);
  
  return (
    <div className='timer'>
      <span>{formatTime(minutes)}</span>:
      <span>{formatTime(seconds)}</span>
    </div>
  )
}


function navigateToLoseScreen(navigate: NavigateFunction) {
  const screenType: EndGameType = 'try_again';
  navigate("/endgame/" + screenType);
}