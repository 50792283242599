import { useLayoutEffect, useRef } from "react";
import { TokenContext } from "./tokenContext";
import { useNavigate } from "react-router-dom";

export default function TokenContextWrapper({ children }: {
    children: React.ReactNode;
}) {
    const tokenState = useRef("");

    const navigate = useNavigate();

    // check for screen resolution
    useLayoutEffect(() => {
      if (document.documentElement.clientWidth >= 768) {
        navigate('/cant_proceed/default');
      }
    }, []);

    return (
      <TokenContext.Provider value={{
        token: tokenState,
      }}>
        {children}
      </TokenContext.Provider>
    );
}