import { Card } from "../components/GameCard";

const getCardPaths = (name: string): [path1: string, path2: string, path3: string] => {
  return [
    `/imgs/dpr_1/cards/${name}.webp`, 
    `/imgs/dpr_2/cards/${name}.webp`, 
    `/imgs/dpr_3/cards/${name}.webp`
  ];
};

export const cardsDataFull: Card[] = [
    {
      uid: 0,
      id_cmp: 1,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('feed'),
      isFlipped: false,
      route: 'feed',
    },
    {
      uid: 1,
      id_cmp: 2,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('staring'),
      isFlipped: false,
      route: 'staring',
    },
    {
      uid: 2,
      id_cmp: 3,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('pieces'),
      isFlipped: false,
      route: 'pieces',
    },
    {
      uid: 3,
      id_cmp: 4,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('soup'),
      isFlipped: false,
      route: 'soup',
    },
    {
      uid: 4,
      id_cmp: 5,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('smiley'),
      isFlipped: false,
      route: 'smiley',
    },
    {
      uid: 5,
      id_cmp: 6,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('hunter'),
      isFlipped: false,
      route: 'hunter',
    },
    {
      uid: 6,
      id_cmp: 1,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('feed'),
      isFlipped: false,
      route: 'feed',
    },
    {
      uid: 7,
      id_cmp: 2,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('staring'),
      isFlipped: false,
      route: 'staring',
    },
    {
      uid: 8,
      id_cmp: 3,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('pieces'),
      isFlipped: false,
      route: 'pieces',
    },
    {
      uid: 9,
      id_cmp: 4,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('soup'),
      isFlipped: false,
      route: 'soup',
    },
    {
      uid: 10,
      id_cmp: 5,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('smiley'),
      isFlipped: false,
      route: 'smiley',
    },
    {
      uid: 11,
      id_cmp: 6,
      backImg: getCardPaths('magnit'),
      frontImg: getCardPaths('hunter'),
      isFlipped: false,
      route: 'hunter',
    }
];