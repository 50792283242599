import type { Card } from "../components/GameCard";

function calcRandIndex(arrLength: number) {
    return Math.floor(Math.random() * arrLength);
}

export function shuffleArrInPlace(arr: Card[]): void {
    for (let i = 0; i < arr.length; ++i) {
        const randIndex = calcRandIndex(arr.length);
        let randIndex2 = calcRandIndex(arr.length);
        
        while (randIndex === randIndex2) {
            randIndex2 = calcRandIndex(arr.length);
        }
        
        const temp = arr[randIndex2];
        arr[randIndex2] = arr[randIndex];
        arr[randIndex] = temp;
    }
}