import { useParams } from "react-router-dom";
import AlreadyPlayed from "../../components/Endgame/AlreadyPlayed";
import TryAgain from "../../components/Endgame/TryAgain";
import Win from "../../components/Endgame/Win";
import { useGameContext } from "../../contexts/GameContext/GameContext";
import "./endgame.css"

export type EndGameType = 'win' | 'played_already' | 'try_again' | '';

export default function Endgame() {
    const { res } = useParams() as { res: EndGameType };
    const gameContext = useGameContext();
    if (!gameContext) {
        throw new Error("Game context is not initialized properly");
    }
    
    const { endGameState } = gameContext;
 
    if (!endGameState && res !== 'played_already') return null; 

    let componentToRender;

    switch (res) {
    case 'win':
        componentToRender = <Win />;
        break;
    case 'try_again': 
        componentToRender = <TryAgain />;
        break;
    case 'played_already':
        componentToRender = <AlreadyPlayed />;
        break;
    default: 
        componentToRender = null;
        break;
    }

    return <>{componentToRender}</>
}