import './GameCard.css'
import type { ItemRouteSlug } from '../../routes/Fact';

export type Card = {
  uid: number;
  id_cmp: number;
  backImg: string[];
  frontImg: string[];
  isFlipped: boolean;
  route: ItemRouteSlug;
};

type Props = {
  handleClick: (key: number) => void;
};

export const GameCard = ({
  uid,
  backImg,
  frontImg,
  isFlipped,
  handleClick,
}: Card & Props) => {

  return (  
    <div 
      className="card" 
      data-flipped={isFlipped} 
      onClick={() => {
        if (!isFlipped) {
          handleClick(uid);
        }
      }}
    >
      <div className="back">
        <div className='card-img'>
          <picture>
            <source srcSet={backImg[0]} media="(max-resolution: 1dppx)" />
            <source srcSet={backImg[1]} media="(min-resolution: 2dppx)" />
            <source srcSet={backImg[2]} media="(min-resolution: 3dppx)" />
            <img draggable="false" className='card-img' src={backImg[1]} alt='' />
          </picture>
        </div>
      </div>
      
      <div className="front">
        <div className='card-img'>
          <picture>
            <source srcSet={frontImg[0]} media="(max-resolution: 1dppx)" sizes='' />
            <source srcSet={frontImg[1]} media="(min-resolution: 2dppx)" />
            <source srcSet={frontImg[2]} media="(min-resolution: 3dppx)" />
            <img draggable="false" className='card-img' src={frontImg[1]} alt='' />
          </picture>
        </div>
      </div>
    </div>
  );
}