import { apiEndpoints } from "./endpoints";
import { AuthUserReturn, GetCouponReturn, GetUserReturn, IncreaseUserAttempsReturn, IssueCouponReturn } from "./returnTypes";


export async function requestAuthUser(token: string): Promise<AuthUserReturn> {
    try {
        const requestUrl = process.env.REACT_APP_BACK_ENDPOINT + apiEndpoints.auth_user;
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const body = await response.json();
        
        return {
            status: response.status,
            body,
        };
    } catch (err) {
        throw new Error("API error:\n" + err);
    }
}


export async function requestGetUser(token: string): Promise<GetUserReturn> {
    try {
        const requestUrl = process.env.REACT_APP_BACK_ENDPOINT + apiEndpoints.get_user;
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const body = await response.json() as Promise<GetUserReturn>;
        return body;
    } catch (err) {
        throw new Error("API error:\n" + err);
    }
}


export async function requestIssueCoupon(token: string): Promise<IssueCouponReturn> {
    try {
        const requestUrl = process.env.REACT_APP_BACK_ENDPOINT + apiEndpoints.issue_coupon;
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const body = await response.json() as Promise<IssueCouponReturn>;
        return body;
    } catch (err) {
        throw new Error("API error:\n" + err);
    }
}


export async function requestGetCoupon(token: string): Promise<GetCouponReturn> {
    try {
        const requestUrl = process.env.REACT_APP_BACK_ENDPOINT + apiEndpoints.get_coupon;
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const body = await response.json() as Promise<GetCouponReturn>;
        return body;
    } catch (err) {
        throw new Error("API error:\n" + err);
    }
}


export async function requestIncreaseUserAttemps(token: string): Promise<IncreaseUserAttempsReturn> {
    try {
        const requestUrl = process.env.REACT_APP_BACK_ENDPOINT + apiEndpoints.set_attempt;
        const response = await fetch(requestUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const body = await response.json();
        return body;
    } catch (err) {
        throw new Error("API error:\n" + err);
    }
}

export async function requestUploadPdf(token: string, base64String: string) {
    try {
        const requestUrl = process.env.REACT_APP_BACK_ENDPOINT + apiEndpoints.upload_pdf;
        const response = await fetch(requestUrl, {
            method: "POST",
            body: JSON.stringify({
                pdf_base64: base64String
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        });
        const body = await response.json();
        return body;
    } catch (err) {
        throw new Error("API error:\n" + err);
    }
}