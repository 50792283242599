import './Rule.css'

export const Rule = (props: {
  text: string
}) => {
  return (
    <li className='rule'>
      <img 
        alt='След'
        src='/icons/track.svg'
      />
      <p className='text'>{props.text}</p>
    </li>
  )
}