import "../App.css";

const getPathDpr = (dpr: number) => `/imgs/dpr_${dpr}/facts/`;

export function FeedFact() {
    return (
        <div className="page__content gap__30">
            <div className="page__descr">
                <p>Сочетать сухой и влажный корм FELIX® в ежедневном рационе вашего любимца вкуснее и дешевле*!</p>
            </div>
            <div className="fact__img">
                <picture>
                    <source 
                        srcSet={getPathDpr(1) + 'feed.webp'} 
                        media="(max-resolution: 1dppx)" 
                    />
                    <source 
                        srcSet={getPathDpr(3) + 'feed.webp'} 
                        media="(min-resolution: 3dppx)" 
                    />
                    <img className="fact__img" 
                        src={getPathDpr(2) + 'feed.webp'} alt=''
                        data-smiley={false} 
                    />
                </picture>
            </div>
            <p className="page__small__descr no_offset marg__bot__20">
                *В соответствии с рекомендациями по кормлению на упаковке стоимость ежедневного рациона, состоящего из влажного и сухого кормов ( в среднем 1 пауч 75г влажного корма FELIX® и 45г сухого корма FELIX® в формате 1,3кг) более чем на 50% ниже стоимости ежедневного рациона, состоящего только из влажного корма FELIX® ( в среднем 4 пауча 75г в день), согласно оптовой цене от производителя. Цена на полке устанавливается продавцом самостоятельно.
            </p>
        </div>
    );
}


export function StaringFact() {
    return (
        <div className="page__content">
            <div className="page__descr gap__10">
                <p>
                    В магазинах Магнит всегда есть корм FELIX® потому что он пользуется большой популярностью в России — <strong className="bold__text">миллионы семей</strong> выбирают для своего пушистого озорника <strong>корм FELIX®*.</strong>
                </p>
                <p className="page__small__descr no_offset">
                    * На основе данных о покупательском поведении Romir Scan Panelза период с июня 2022 по май 2023 года в России.
                </p>
            </div>
            <div className="fact__img">
                <picture>
                    <source 
                        srcSet={getPathDpr(1) + 'staring.webp'} 
                        media="(max-resolution: 1dppx)" 
                    />
                    <source 
                        srcSet={getPathDpr(3) + 'staring.webp'} 
                        media="(min-resolution: 3dppx)" 
                    />
                    <img className="fact__img" 
                        src={getPathDpr(2) + 'staring.webp'} alt=''
                        data-smiley={false} 
                    />
                </picture>
            </div>
        </div> 
    );
}

export function PiecesFact() {
    return (
        <div className="page__content">
            <div className="page__descr">
                <p>
                    Мы помним, что ваш озорник — в первую очередь хищник, именно поэтому в ассортименте магазинов Магнит вы найдете корм <strong className="bold__text">FELIX® Мясное объедение. </strong>
                </p>
                <p>
                    Это сухой, хрустящий корм с насыщенным мясным вкусом и повышенным содержанием белка. Ваш маленький охотник <strong className="bold__text">будет здоров, силен, полон энергии и доволен.</strong>
                </p>
            </div>
            <div className="fact__img">
                <picture>
                    <source 
                        srcSet={getPathDpr(1) + 'pieces.webp'} 
                        media="(max-resolution: 1dppx)" 
                    />
                    <source 
                        srcSet={getPathDpr(3) + 'pieces.webp'} 
                        media="(min-resolution: 3dppx)" 
                    />
                    <img className="fact__img" 
                        src={getPathDpr(2) + 'pieces.webp'} alt=''
                        data-smiley={false} 
                    />
                </picture>
            </div>
        </div>  
    );
}

export function SoupFact() {
    return (
        <div className="page__content">
            <div className="page__descr">
                <p>
                    В ассортименте кормов FELIX® в магазинах Магнит есть <strong className="bold__text">суп для взрослых кошек</strong> — с него можно начинать обед.
                </p>
                <p>
                    Это влажный корм с соусом и крупными кусочками мяса, который дополнит рацион и поможет поддержать водный баланс вашего озорника.
                </p>
            </div>
            <div className="fact__img">
                <picture>
                    <source 
                        srcSet={getPathDpr(1) + 'soup.webp'} 
                        media="(max-resolution: 1dppx)" 
                    />
                    <source 
                        srcSet={getPathDpr(3) + 'soup.webp'} 
                        media="(min-resolution: 3dppx)" 
                    />
                    <img className="fact__img" 
                        src={getPathDpr(2) + 'soup.webp'} alt=''
                        data-smiley={false} 
                    />
                </picture>
            </div>
        </div>   
    );
}


export function SmileyFact() {
    return (
        <div className="page__content">
            <div className="page__descr">
                <p>
                    Кошки исполняют мечту многих людей и проводят <strong className="bold__text">70% своей жизни во сне!</strong>
                </p>
                <p>
                    То есть получается, что здоровый 6-летний кот бодрствовал в своей жизни всего каких-то пару лет.
                </p>
                <p>
                    Все дело в генах хищника — для охоты нужно <strong className="bold__text">много энергии</strong>, а следовательно, и много времени для сна, чтобы ее восполнить.
                </p>
            </div>
            <div className="fact__img">
                <picture data-smiley="true">
                    <source 
                        srcSet={getPathDpr(1) + 'smiley.webp'} 
                        media="(max-resolution: 1dppx)" 
                    />
                    <source 
                        srcSet={getPathDpr(3) + 'smiley.webp'} 
                        media="(min-resolution: 3dppx)" 
                    />
                    <img className="fact__img" 
                        src={getPathDpr(2) + 'smiley.webp'} alt=''
                        data-smiley={true} 
                    />
                </picture>
            </div>
        </div> 
    );
}

export function HunterFact() {
    return (
        <div className="page__content">
            <div className="page__descr">
                <p><strong className="bold__text">Влажные корма FELIX®</strong> — это такое разнообразное меню, что за 21 день вкус не повторится ни разу.</p>
                <p>Поэтому в магазинах Магнит вы точно <strong className="bold__text">найдете тот вкус</strong>, который придется по нраву даже самой взыскательной кошке.</p>
            </div>
            <div className="fact__img">
                <picture>
                    <source 
                        srcSet={getPathDpr(1) + 'hunter.webp'} 
                        media="(max-resolution: 1dppx)" 
                    />
                    <source 
                        srcSet={getPathDpr(3) + 'hunter.webp'} 
                        media="(min-resolution: 3dppx)" 
                    />
                    <img className="fact__img" 
                        src={getPathDpr(2) + 'hunter.webp'} alt=''
                        data-smiley={false} 
                    />
                </picture>
            </div>
        </div> 
    );
}