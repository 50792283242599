import { useEffect, useState } from "react";
import JustButton from "../JustButton/JustButton";
import "../../App.css";
import "./style.css";
import { requestGetCoupon, requestIssueCoupon } from "../../api/requests";
import { useTokenContext } from "../../contexts/tokenContext/tokenContext";
import { CouponData } from "../../api/returnTypes";
import PdfTemplateNew from "./PdfTemplate";

const getPathDpr = (dpr: number) => `/imgs/dpr_${dpr}/endgame/win.webp`;

export default function Win() {
    const tokenContext = useTokenContext();
    if (!tokenContext) {
        throw new Error("Token context isn't initialized properly"); 
    }
   
    const [couponData, setCouponData] = useState<CouponData | null>(null);

    useEffect(() => {
        requestIssueCoupon(tokenContext.token.current)
            .then(res => {
                if ('coupon' in res) {
                    setCouponData(res.coupon);
                }
                else {
                    requestGetCoupon(tokenContext.token.current)
                        .then(res => {
                            setCouponData(res.coupon);
                        });
                }
            });
    }, []);

    const [isTicketSaved, setIsTickedSaved] = useState(false);
    
    const [showCoupon, setShowCoupon] = useState(false);

    function handleGeneratePdfClick() {
        if (couponData) {
            setShowCoupon(true);
        }
    }

    if (!isTicketSaved && showCoupon && couponData?.barcode.barcode_base64) {
        return (
            <PdfTemplateNew 
                base64ImgStr={couponData.barcode.barcode_base64}
                setIsTicketSaved={setIsTickedSaved} 
                token={tokenContext.token.current}
            />
        );
    }
    else {
        return (
            <main className="page__main win__bg-img">
               <div className="page__content">
                <h2 className="page__title">Поздравляем!</h2>
                    <p className="page__descr no__flex">Вы получили купон на <strong className="strong__text">50 руб.</strong> для оплаты товаров бренда FELIX® в магазинах Магнит.</p>
    
                    <div className={
                        `ticket__img ${!isTicketSaved && couponData && 'qr'}`
                    }>
                        {isTicketSaved && (
                            <picture>
                                <source srcSet={getPathDpr(1)} media="(max-resolution: 1dppx)" />
                                <source srcSet={getPathDpr(2)} media="(min-resolution: 2dppx)" />
                                <source srcSet={getPathDpr(3)} media="(min-resolution: 3dppx)" />
                                <img 
                                    className="ticket__img" 
                                    src={getPathDpr(2)} 
                                    alt="" 
                                />
                            </picture>
                        )}
                        {!isTicketSaved && couponData && (
                            <img 
                                className="ticket__img qr" 
                                src={couponData.barcode.barcode_base64} 
                                alt="" 
                            />
                        )}
                    </div>
                    
                    <p className="page__descr text__center mb-16 mt-auto">Не забудьте сделать скриншот, если не началось автоматическое скачивание</p>
                    <JustButton 
                        variant={isTicketSaved ? 'opacity' : 'white'}
                        onClick={handleGeneratePdfClick}
                        addedClasses={['no_mg_top']}
                    >
                        {isTicketSaved ? 'Купон сохранён' : 'Открыть купон'}
                    </JustButton>
               </div>
            </main>
        );
    }
}