import { useParams } from 'react-router-dom';
import './Thumbnail.css'
import "../../App.css";

const getPathDprFelix = (name: string, dpr: number) => `/imgs/dpr_${dpr}/${name}.webp`;
const getPathDprEndgame = (dpr: number) => `/imgs/dpr_${dpr}/endgame/try_again.webp`;

export type ThumbReason = 'default' | 'comback_tomorrow' | 'user_tries_ended' | 'coupons_ended';

type RouterParams = { reason: ThumbReason };

export default function Thumbnail() {

  const params = useParams() as RouterParams;

  let TitleElement: React.JSX.Element;
  let DescriptionElement: React.JSX.Element | null;
  let PictureElement: React.JSX.Element;

  switch (params.reason) {
  case 'default':
      TitleElement = (
        <>
          <h1 className='thumbnail__title thumbnail__title--desk'>
            игра доступна<br/>
            только в мобильной версии
          </h1>
          <h1 className='thumbnail__title thumbnail__title--mob'>
            увы, вам нужно<br/>повторно<br/>запустить игру
          </h1>
        </>
      );

      DescriptionElement = null;

      PictureElement = (
        <picture>
          <source srcSet={getPathDprFelix('felix-thumbnail', 1)} media="(max-resolution: 1dppx)" />
          <source srcSet={getPathDprFelix('felix-thumbnail', 2)} media="(max-resolution: 2dppx)" />
          <source srcSet={getPathDprFelix('felix-thumbnail', 3)} media="(min-resolution: 3dppx)" />
          <img 
            alt='Феликс'
            className='felix'
            src={getPathDprFelix('felix-thumbnail', 2)}
          />
        </picture>
      );

      break;

  case 'comback_tomorrow':
      TitleElement = (
        <>
          <h1 className='thumbnail__title thumbnail__title--desk'>
            игра доступна<br/>
            только в мобильной версии
          </h1>
          <h1 className='thumbnail__title thumbnail__title--mob'>
            Возвращайтесь<br/>завтра!
          </h1>
        </>
      );

      DescriptionElement = (
        <p className="page__descr thumbnail__descr">
          К сожалению, купоны закончились.<br/>Попробуйте сыграть завтра.
        </p>
      );

      PictureElement = (
        <picture>
						<source srcSet={getPathDprEndgame(1)} media="(max-resolution: 1dppx)" />
						<source srcSet={getPathDprEndgame(2)} media="(min-resolution: 2dppx)" />
						<source srcSet={getPathDprEndgame(3)} media="(min-resolution: 3dppx)" />
						<img
							className="page__img ticket__img"
							src={getPathDprEndgame(2)}
							alt=""
						/>
					</picture>
      );

      break;
      
  case 'user_tries_ended':
    TitleElement = (
      <>
        <h1 className='thumbnail__title thumbnail__title--desk'>
          игра доступна<br/>
          только в мобильной версии
        </h1>
        <h1 className='thumbnail__title thumbnail__title--mob'>
          Ваши попытки<br/>закончились
        </h1>
      </>
    );

    DescriptionElement = (
      <p className="page__descr thumbnail__descr">
        К сожалению, вы не успели найти все карточки.<br/>В следующий раз удача будет на вашей стороне!
      </p>
    );

    PictureElement = (
      <picture>
          <source srcSet={getPathDprEndgame(1)} media="(max-resolution: 1dppx)" />
          <source srcSet={getPathDprEndgame(2)} media="(min-resolution: 2dppx)" />
          <source srcSet={getPathDprEndgame(3)} media="(min-resolution: 3dppx)" />
          <img
            className="page__img ticket__img"
            src={getPathDprEndgame(2)}
            alt=""
          />
        </picture>
    );

    break;

  case 'coupons_ended':
    TitleElement = (
      <>
        <h1 className='thumbnail__title thumbnail__title--desk'>
          игра доступна<br/>
          только в мобильной версии
        </h1>
        <h1 className='thumbnail__title thumbnail__title--mob'>
          Купоны<br/>закончились
        </h1>
      </>
    );

    DescriptionElement = (
      <p className="page__descr thumbnail__descr">К сожалению, все купоны закончились.<br/>Не расстраивайтесь, в следующий раз обязательно повезет!</p>
    );

    PictureElement = (
      <picture>
          <source srcSet={getPathDprEndgame(1)} media="(max-resolution: 1dppx)" />
          <source srcSet={getPathDprEndgame(2)} media="(min-resolution: 2dppx)" />
          <source srcSet={getPathDprEndgame(3)} media="(min-resolution: 3dppx)" />
          <img
            className="page__img ticket__img"
            src={getPathDprEndgame(2)}
            alt=""
          />
        </picture>
    );

    break;
  }

  return (
    <main className='thumbnail__wrapper' data-thumb={params.reason === 'default' ? 'default' : 'try_again'}>
      <div className='thumbnail__title__block'>
        {TitleElement}
        {Boolean(DescriptionElement) && DescriptionElement}
        {PictureElement}
      </div>
    </main>
  )
}