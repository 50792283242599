import './StartPage.css';
import { requestAuthUser } from '../../api/requests';
import { useEffect, useState } from 'react';
import { useTokenContext } from '../../contexts/tokenContext/tokenContext';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getSearchParam } from '../../utils/getSearchParam';
import JustButton from '../../components/JustButton/JustButton';
import { ThumbReason } from '../Thumbnail';

const getPathDpr = (name: string, dpr: number) => `/imgs/dpr_${dpr}/${name}.webp ${dpr}x`;

export const StartPage = () => {
  const tokenContext = useTokenContext();
  if (!tokenContext) {
    throw new Error("token context isn't initialized properly");
  }

  const [isLoading, setIsLoading] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // if screen not mobile - redirect
    if (document.documentElement.clientWidth > 767) {
      redirectToCantProceed(navigate, 'default');
      return;
    }

    // if user come from already_played page
    const fromAlreadyParam = getSearchParam('from_already');
    if (fromAlreadyParam) {
      setCanPlay(true);
      return;
    }

    const tokenParam = getSearchParam('token');

    if (tokenParam) {
      tokenContext.token.current = tokenParam;

      setIsLoading(true);
      // prod version
      requestAuthUser(tokenParam)
        .then(res => {
          // success case
          if ('status' in res && res.status) {
            switch (res.status) {
              case 200:
                // already played
                if ('gameStatus' in res.body && res.body.gameStatus  === 'passed') {
                  redirectToAlreadyPlayed(navigate);
                }
                // exausted attempts
                else if ('attempts' in res.body && res.body.attempts > 1) {
                  redirectToCantProceed(navigate, 'user_tries_ended');
                }
                // can play
                else {
                  setCanPlay(true);
                }
                break;
              // forbidden
              case 403:
                if ('type' in res.body && res.status === 403) {
                  if ('has_passed' in res.body && res.body.has_passed) {
                    redirectToAlreadyPlayed(navigate);
                  }
                  else if (res.body.type === 'general_limit') {
                    redirectToCantProceed(navigate, 'coupons_ended');
                  }
                  else if (res.body.type === 'daily_limit') {
                    redirectToCantProceed(navigate, 'comback_tomorrow');
                  }
                  break;
                }
              // unathorized
              case 401:
                redirectToCantProceed(navigate, 'default');
                break;
            }
          }
          else {
            redirectToCantProceed(navigate, 'default');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
      // no token - can't proceed
      else {
        redirectToCantProceed(navigate, 'default');
      }

  }, []);

  return (
    <div className='main__wrapper'>
      {isLoading && <></>}
      {!isLoading && (
        <>
          <div className='magnit__logo'>
            <picture>
              <source srcSet={getPathDpr('magnit-logo', 1)} media="(max-resolution: 1dppx)" />
              <source srcSet={getPathDpr('magnit-logo', 2)} media="(min-resolution: 2dppx)" />
              <source srcSet={getPathDpr('magnit-logo', 3)} media="(min-resolution: 3dppx)" />
              <img 
                alt='Логотип Магнита'
                className='magnit__logo'
                src={getPathDpr('magnit-logo', 2)}
              />
            </picture>
          </div>

          <div className='title__block'>
            <h1 className='startpage__title'>Здорово праздновать с&nbsp;котом!</h1>
            <div className='felix'>
              <picture>
                <source srcSet={getPathDpr('felix-thumbnail', 1)} media="(max-resolution: 1dppx)" />
                <source srcSet={getPathDpr('felix-thumbnail', 2)} media="(min-resolution: 2dppx)" />
                <source srcSet={getPathDpr('felix-thumbnail', 3)} media="(min-resolution: 3dppx)" />
                <img 
                  alt='Феликс'
                  className='felix'
                  src={getPathDpr('felix-thumbnail', 2)}
                />
              </picture>
            </div>
          </div>

          <JustButton 
            disabled={!canPlay}
            variant='white' 
            onClick={() => navigate("/rules")}
          >
            Играть
          </JustButton>
        </>
      )}
    </div>
  );
}

function redirectToAlreadyPlayed(navigate: NavigateFunction) {
  navigate("/endgame/played_already");
}

export function redirectToCantProceed(navigate: NavigateFunction, reason: ThumbReason) {
  navigate("/cant_proceed/" + reason);
}