import JustButton from "../JustButton/JustButton";
import "../../App.css";
import "./style.css";
import { LinkButton } from "../LinkButton";
import { useEffect, useState } from "react";
import { requestGetCoupon } from "../../api/requests";
import { useTokenContext } from "../../contexts/tokenContext/tokenContext";
import { CouponData } from "../../api/returnTypes";
import PdfTemplateNew from "./PdfTemplate";

const getPathDpr = (dpr: number) => `/imgs/dpr_${dpr}/endgame/already_played.webp`;

export default function AlreadyPlayed() {
    const tokenContext = useTokenContext();
    if (!tokenContext) {
        throw new Error("Token context isn't initialized properly");

    }
    const [couponData, setCouponData] = useState<CouponData | null>(null);

    useEffect(() => {
        requestGetCoupon(tokenContext.token.current)
            .then(res => setCouponData(res.coupon));
    }, []);

    const [isTicketSaved, setIsTickedSaved] = useState(false);

    const [showCoupon, setShowCoupon] = useState(false);

    function handleGeneratePdfClick() {
        if (couponData) {
            setShowCoupon(true);
        }
    }

    if (!isTicketSaved && showCoupon && couponData?.barcode.barcode_base64) {
        return (
            <PdfTemplateNew 
                base64ImgStr={couponData.barcode.barcode_base64}
                setIsTicketSaved={setIsTickedSaved} 
                token={tokenContext.token.current}
            />
        );
    }
    else {
        return (
            <main className="page__main try_again__bg-img">
                <div className="page__content">
                    <h2 className="page__title already__title">Вы уже сыграли в&nbsp;игру с&nbsp;Феликсом!</h2>
                        <p className="page__descr">Совершайте покупки в&nbsp;приложении Магнит и&nbsp;радуйте своих питомцев вкуснятиной!</p>
                        <div className="page__img ticket__img">
                            <picture>
                                <source srcSet={getPathDpr(1)} media="(max-resolution: 1dppx)" />
                                <source srcSet={getPathDpr(2)} media="(min-resolution: 2dppx)" />
                                <source srcSet={getPathDpr(3)} media="(min-resolution: 3dppx)" />
                                <img 
                                    className="page__img ticket__img" 
                                    src={getPathDpr(2)} 
                                    alt="" 
                                />
                            </picture>
                        </div>
                        <JustButton 
                            variant={!isTicketSaved ? 'blue' : 'opacity'}
                            onClick={handleGeneratePdfClick}
                            addedClasses={['mb-20']}
                        >
                            {isTicketSaved ? 'Купон сохранён' : 'Открыть купон'}
                        </JustButton>
                        <LinkButton text="Вернуться назад" to="/?from_already=true" noMarginTop={true} />
                </div>
            </main>
        );
    }
}