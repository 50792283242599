import { Card, GameCard } from '../GameCard'
import './GameField.css'
import { SetStateAction, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { FlippedCardData, ITimerState, useGameContext } from '../../contexts/GameContext/GameContext';

type Props = {
  timerState: ITimerState;
  setTimerState: React.Dispatch<SetStateAction<ITimerState>>;
};

export const GameField = ({
  timerState,
  setTimerState,
}: Props) => {

  const gameContext = useGameContext();
  if (!gameContext) {
    throw new Error("Game context is not initialized properly");
  }

  const { cardsState, setCardsState, flippedCardState, setFlippedCardState } = gameContext;
  const areClicksDisabled = useRef(false);
  const navigate = useNavigate();

  const handleFlipCardClick = (flippedUid: number) => {
    // can't flipp more than 2 cards within single game iteration
    if (flippedCardState.length >= 2 || areClicksDisabled.current || !timerState.isRunning) return;

    // initialized for typescript, with invalid value
    // would be re-initialized in the loop with 100% probability
    let currFlippedCardData: FlippedCardData = {
      uid: -1,
      id_cmp: -1,
      route: '',
    };
    
    // instead of .map for performans reasons
    let newCardState: Card[] = [];
    for (const card of cardsState) {
      if (card.uid === flippedUid) {
        // set flippedCardData state
        currFlippedCardData.uid = card.uid;
        currFlippedCardData.id_cmp = card.id_cmp;
        currFlippedCardData.route = card.route;
        
        newCardState.push({...card, isFlipped: true});
      }
      else {
        newCardState.push(card);
      }
    }

    setCardsState(newCardState);
    setFlippedCardState([...flippedCardState, currFlippedCardData]);
  };



  useEffect(() => {
    if (flippedCardState.length === 2) {
      areClicksDisabled.current = true;

      const isEqual = flippedCardState[0].id_cmp === flippedCardState[1].id_cmp;
      if (isEqual) {
        // freeze timer, timer resumes inside /fact:slug page
        setTimerState({
          minutes: timerState.minutes,
          seconds: timerState.seconds,
          isRunning: false,
        });
        
        // navigate to fact
        setTimeout(() => {
          setFlippedCardState([]);
          areClicksDisabled.current = false;
          navigate("/fact/" + flippedCardState[0].route);
        }, 500);
      }
      else {
        // error, close current flipped cards
        setTimeout(() => {
          setFlippedCardState([]);
          setCardsState(unflippCardsWithId(cardsState, flippedCardState));
          areClicksDisabled.current = false;
        }, 400);
      }
    }
  }, [flippedCardState]);

  return (
    <div className='card-grid'>
      {cardsState.map((card) => {
        return (
          <GameCard
            key={card.uid}
            uid={card.uid}
            id_cmp={card.id_cmp}
            frontImg={card.frontImg}
            backImg={card.backImg}
            isFlipped={card.isFlipped}
            handleClick={handleFlipCardClick}
            route={card.route}
          />
        );
      })}
    </div>
  )
}

// utils

function unflippCardsWithId(cards: readonly Card[], flippedCardData: FlippedCardData[]): Card[] {
  const newCardsState: Card[] = [...cards];

  for (const data of flippedCardData) {
    const cardToUnflipp = newCardsState.find(c => c.uid === data.uid);
    if (cardToUnflipp) {
      cardToUnflipp.isFlipped = false;
    }
  }

  return newCardsState;
}