export function getSearchParam(name: string): string | null {
    const params = new URLSearchParams(document.location.search);
  
    if (params.has(name)) {
      return params.get(name);
    }
    else {
      return null;
    }
}
  