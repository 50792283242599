export const rulesData = [
  {
    "text": "Переворачивайте по\xa0очереди карточки, пока не соберете\xa0все парные картинки. У\xa0вас будет две попытки."
  },

  {
    "text": "Чем больше пар вы сможете найти,тем больше интересных фактов о\xa0котах и\xa0бренде FELIX® вы сможете узнать."
  },

  {
    "text": "Когда все карточки будут распределены по парам, вы\xa0получите купон\xa0на скидку для покупки корма FELIX® в\xa0Магнит."
  }
]