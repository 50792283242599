import { useCallback, useState } from "react";
import { Card } from "../../components/GameCard";
import { FlippedCardData, GameContext, ITimerState } from "./GameContext";
import { cardsDataFull } from '../../data/cards';
import { shuffleArrInPlace } from '../../utils/shuffle'

shuffleArrInPlace(cardsDataFull);

export default function GameContextWrapper({ children }: { children: React.ReactNode }) {
    const [cardsState, setCardsState] = useState<Card[]>(cardsDataFull);
    const [flippedCardState, setFlippedCardState] = useState<FlippedCardData[]>([]);
    const [timerState, setTimerState] = useState<ITimerState>({
        minutes: 0,
        seconds: 40,
        isRunning: true,
    });
    const [endGameState, setEndGameState] = useState<boolean>(false);

    const resetGameState = useCallback(() => {
        setCardsState(cardsDataFull);
        setFlippedCardState([]);
        setTimerState({
            minutes: 0,
            seconds: 40,
            isRunning: true,
        });
        setEndGameState(false);
    }, []);

    return (
        <GameContext.Provider value={{
            cardsState,
            setCardsState,
            flippedCardState,
            setFlippedCardState,
            timerState,
            setTimerState,
            endGameState,
            setEndGameState,
            resetGameState,
        }}>
            {children}
        </GameContext.Provider>
    );
}