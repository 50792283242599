import classNames from 'classnames'
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom'
import './LinkButton.css'

export const LinkButton  = (props: {
  className?: string;
  to: string;
  text: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  noMarginTop?: boolean;
}) => {
  return (
    <Link
      className={classNames('link', props.className)}
      to={props.to}
      onClick={props.onClick}
      data-no-margin-top={props.noMarginTop}
    >
      {props.text}
    </Link>
  )
}