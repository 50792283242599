import { createContext, SetStateAction, useContext } from "react";
import type { ItemRouteSlug } from "../../routes/Fact";
import type { Card } from "../../components/GameCard";

export type FlippedCardData = {
    uid: number;
    id_cmp: number;
    route: ItemRouteSlug | '';
};

export type ITimerState = {
    minutes: number;
    seconds: number;
    isRunning: boolean;
};

export interface IGameContext {
    cardsState: Card[];
    setCardsState: React.Dispatch<SetStateAction<Card[]>>;
    flippedCardState: FlippedCardData[];
    setFlippedCardState: React.Dispatch<SetStateAction<FlippedCardData[]>>;
    timerState: ITimerState;
    setTimerState: React.Dispatch<SetStateAction<ITimerState>>;
    endGameState: boolean;
    setEndGameState: React.Dispatch<SetStateAction<boolean>>;
    resetGameState: () => void;
};

export const GameContext = createContext<IGameContext | null>(null);

export function useGameContext() {
    return useContext(GameContext);
}